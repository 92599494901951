import { useSelector } from 'react-redux';
import sendEvent from '../../lib/sendChugEvent';

const useTracking = () => {

  const { lpdata: { properties = {} } = {}, sitetag, slug, domain, category } = useSelector(state => state.config);
  const tracking = useSelector(state => state.tracking) || {};

  const newTrackUrl =  properties.trackLink ? properties.trackLink.replace("/tracking/go","/track/go.php") : '' ;

  const trackUrl = appendLinkParameters(`${newTrackUrl}&noredirect=true&disableRouting=1&cs=lp&referer_url=${encodeURI(document.referrer)}`, tracking);
  const joinLink = appendLinkParameters(properties.joinLink, tracking);

  const joinLinkRedirect = properties.joinLinkRedirect || null;
  const joinLinkTarget = properties.joinLinkTarget || null;

  const sendChugEvent = (eventType, data) => {
    data.sitetag = sitetag;
    data.slug = slug;
    data.domain = domain;
    if (category) data.category = category;
    sendEvent(tracking, eventType, data);
  };

  const trackAndRedirect = (payload, customUrl = null, target = null, clip_id = null,event_type='click',disableJoinLinkRedirect=false) => {
    sendChugEvent(event_type, payload);

    let finalUrl = replaceDynamicParameters(customUrl ? customUrl : joinLink, tracking, clip_id);

    if ((target === '_blank' || joinLinkTarget === '_blank' || joinLinkRedirect) && !disableJoinLinkRedirect) {
      window.open(finalUrl, '_blank');
      if (joinLinkRedirect) window.location.href = replaceDynamicParameters(joinLinkRedirect, tracking, clip_id);
    }
    else window.location.href = finalUrl;
  };
  return { sendChugEvent, trackAndRedirect, trackUrl };
};

export default useTracking;

const appendLinkParameters = (link, tracking) => {
  if (!link || !typeof "") return link;

  if (link.indexOf('?') === -1) link = link + '?';
  if (tracking.adv_id) {
    link += `&ad=${tracking.adv_id}`;
  }
  if (tracking.campaign) {
    link += `&campaign=${tracking.campaign}`;
  }
  if (tracking.acampaign) {
    link += `&acampaign=${tracking.acampaign}`;
  }
  if (tracking.gsub_id) {
    link += `&gsub_id=${tracking.gsub_id}`;
  }
  if (tracking.gallery_id) {
    link += `&gallery_id=${tracking.gallery_id}`;
  }
  if (tracking.skin_id) {
    link += `&skin_id=${tracking.skin_id}`;
  }
  if (tracking.subprogram_id) {
    link += `&su=${tracking.subprogram_id}`;
  }
  if (tracking.productgroup_id) {
    link += `&pg=${tracking.productgroup_id}`;
  }
  if (tracking.oneclick) {
    link += `&1click=${tracking.oneclick}`;
  }
  if (tracking.oneclick_email_address) {
    link += `&1click_email_address=${tracking.oneclick_email_address}`;
  }
  if (tracking.oneclick_custom_code_id) {
    link += `&1click_custom_code_id=${tracking.oneclick_custom_code_id}`;
  }
  if (tracking.oneclick_crypted_string) {
    link += `&1click_crypted_string=${tracking.oneclick_crypted_string}`;
  }
  if (tracking.rtb_click_id) {
    link += `&rtb_click_id=${tracking.rtb_click_id}`;
  }
  return link;
};

const replaceDynamicParameters = (link, tracking, clip_id = null) => {
  if (!link || !typeof "") return null;
  if (link.indexOf('?') === -1) link = link + '?';

  /*eslint no-template-curly-in-string: "off"*/
  link = link.replace("${adv_id}", tracking.adv_id || "");
  link = link.replace("${subprogram_id}", tracking.subprogram_id || "");
  link = link.replace("${campaign}", tracking.campaign || "");
  link = link.replace("${acampaign}", tracking.acampaign || "");
  link = link.replace("${gsub_id}", tracking.gsub_id || "");
  link = link.replace("${gallery_id}", tracking.gallery_id || "");
  link = link.replace("${skin_id}", tracking.skin_id || "");
  link = link.replace("${clip_id}", clip_id || "");
  link = link.replace("${rtb_click_id}", tracking.rtb_click_id || "");
  return link;
};
