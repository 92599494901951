import React from 'react';
import styled from 'styled-components';

const TextAreaWrapper = styled.div `
    text-align: center;
    position: relative;
    max-width: 1920px;
    margin: 0 auto 28px;
    padding: 0 100px 0 100px;


    @media (max-width: 995px) {
        padding: 0 60px 0;
    }

    @media (max-width: 700px) {
        padding: 0 30px 0;
    }
`;

const Separator = styled.div `
    padding-bottom: 70px;
    border-top: 1px solid ${p => p.separatorColor};

    @media (max-width: 995px) {
        padding-bottom: 60px;
    }

    @media (max-width: 700px) {
        padding-bottom: 60px;
    }
`;

const Title = styled.h4 `
    color: ${p => p.titleColor};
    margin: 0px;
    font-size: 40px;
    font-weight: 300;
    line-height: 1.25;
    text-transform: uppercase;

    strong { font-weight: 600; }

    @media (max-width: 700px) {
        margin-bottom: 15px;
    }

    @media (max-width: 995px) {
        font-size: 5.5vw;
        letter-spacing: 1px;
    }
`;

const Description = styled.p `
    color: ${p => p.descriptionColor};
    line-height: 1.6;
    font-size: 23px;
    text-align: justify;
    margin-bottom: 19px;
    
    @media (max-width: 700px) {
        font-size: 18px;
    }
`;


const TextArea = ({
    title = '',
    titleColor = '#fff',
    description = '',
    descriptionColor = '#ccc',
    separatorColor = '#5e6989'
}) => (
    <TextAreaWrapper>
		<Separator separatorColor={separatorColor} />
		<Title dangerouslySetInnerHTML={{ __html: title }} titleColor={titleColor} />
		<Description dangerouslySetInnerHTML={{ __html: description }} descriptionColor={descriptionColor} />
	</TextAreaWrapper>
);

export default TextArea;
