import React from 'react';
import './SiteHeader.css';
import useTracking from '../Tracking/useTracking';

const SiteHeader = ({ headerProperties }) => {
	let {
		sitename,
		logo,
		slogan,
		loginLink,
		loginLinkTarget,
		background,
		sloganColor,
		logoHeight,
		borderColor,
		loginColor,
		joinColor,
		joinBackground,
		loginText = 'LOGIN',
		joinText = 'BECOME A MEMBER >'
	} = headerProperties;

	const { trackAndRedirect, sendChugEvent } = useTracking();

	const handleJoin = (elem) => e => {
		e.stopPropagation();
		e.preventDefault();
		let payload = { component: 'Header', element: elem };
		trackAndRedirect(payload);
	};

	const handleLogin = (elem) => e => {
		e.stopPropagation();
		e.preventDefault();
		let payload = { component: 'Header', element: elem };
		sendChugEvent('click', payload);
		if (loginLinkTarget === '_blank') {
			window.open(loginLink, loginLinkTarget);
		}
		else {
			window.location.href = loginLink;
		}
	};

	return (
		<div className="headerContainer">
			<header className="header" style={{ background: background || '#000', borderBottomColor: borderColor || '#404040' }}>
				<div className="headerWrapper">
					<a onClick={handleJoin('logo')} href="/join" style={{ color: sloganColor || '#FFF' }}>
						<h1><div style={{ height: logoHeight || '25px', width: '100%' }}><img src={logo} alt={sitename} /></div></h1>
						<h2><span dangerouslySetInnerHTML={{ __html: slogan }} /></h2>
					</a>
					<nav>
						<div className="headerLogin" style={{ borderTopColor: borderColor || '#404040' }}>
							<a onClick={handleLogin('loginButton')} href={loginLink} style={{ color: loginColor || '#FFF' }}>{loginText}</a>
						</div>
						<div className="headerJoin" style={{ background: joinBackground || '#FFF' }}>
							<a onClick={handleJoin('joinButton')} className="lp-join-link" href='/join' style={{ color: joinColor || '#000' }}>{joinText}</a>
						</div>
					</nav>
				</div>
			</header>
		</div>
	);
};


export default SiteHeader;
