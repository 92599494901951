import React from 'react';
import styled from 'styled-components';
import useTracking from '../Tracking/useTracking';
import { getHorizontalOptimizedImageUrl, getOptimizedFormatImageUrl } from '../../lib/utils';

const friendlyAlignment = (align) => {
    switch (align) {
        case 'left':
            return 'flex-start';
        case 'right':
            return 'flex-end';
        case 'top':
            return 'flex-start';
        case 'bottom':
            return 'flex-end';
        case 'center':
                return 'center';            
        default:
            return align;
    }

}

const PresentationWrapper = styled.div`
    position: relative;
    height: calc(100vh - ${p => p.headerOffset}px);
    background: url(${p => getHorizontalOptimizedImageUrl(p.backgroundImage, p.imageQuality)}) no-repeat 90% top/cover;
    margin: 0;
    padding: 0;
    border: 0;

    @media (max-width: 995px){
        min-height: 70vh;
        font-size: 17px;
    }
`;

const PresentationRaster = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: url(${p => getOptimizedFormatImageUrl(p.rasterImage)}) no-repeat 0 0/cover;
    z-index: 0;

    @media (max-width: 995px){
        background: ${p => { return p.mobileRasterImage ? `url(${getOptimizedFormatImageUrl(p.mobileRasterImage)})` : "rgba(0, 1, 22, .7)" }};
    }
`;

const SectionWrapper = styled.div`
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align:${p => { return p.alignment === 'right' ? 'right' : 'left' }};
    flex-wrap: wrap;
    height: 100%;
    padding: 70px 100px;

    @media (max-width: 995px){
        display: block;
        display: flex;
        flex-direction: row;
        width: 100%;
        padding: 25px;
        text-align: center;
        justify-content: center;
    }

    @media (max-width: 700px){
        padding-bottom: 80px;
    }
`;

const SectionLeft = styled.div`
    width: 100% ;
    height:100%;
    max-width: 850px;
    top:0;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: ${p => p.verticalAlignment};
    @media(max-width: 995px) {
        max-width: none;
        align-items: center;
    }
`;

const SectionRight = styled.div`
    width: 100% ;
    height:100%;
    right:0;
    display: flex;
    justify-content: ${p => p.verticalAlignment};
    flex-direction: column;
    align-items: end;
    @media(max-width: 995px) {
        max-width: none;
        align-items: center;
    }
`;
const SectionCentered = styled.div`
    width: 100% ;
    height: 100%;
    align-items: center;
    display: flex;
    justify-content: ${p => p.verticalAlignment};
    flex-direction: column;
`;

const Section = styled.div`
    width: 100%;
    max-width: ${p=> p.alignment?'unset':'850px'};
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: ${p => friendlyAlignment(p.alignment)};    
    justify-content: ${p => friendlyAlignment(p.verticalAlignment)};
    @media(max-width: 995px) {
        max-width: none;
        align-items: center;
    }    
`;
const Title = styled.h1`
    color: ${p => p.titleColor};
    margin:0;
    font-size: 38px;
    font-weight: 500;
    line-height: 1.25;
    text-transform: uppercase;
    
    @media (max-width: 700px){
        font-size: 6.8vw;
    }
    
    @media (max-width: 995px){
        letter-spacing: 1px;
    }
`;

const Description = styled.p`
    color: ${p => p.descriptionColor};
    padding-bottom: 19px;
    font-size: 142% ;
    line-height: 1.5;
    margin: 0;
    @media (max-width: 700px){
        font-size: 16px;
    }

    strong {
        display: block;
        @media (max-width: 995px) {
            display: inline-block;
        }
    }
`;

const Button = styled.a`
    display: inline-block;
    transition: background .3s;
    padding: 15px 50px;
    color: ${p => p.buttonColor};
    background: ${p => p.buttonBackground[0] || p.buttonBackground};
    background: linear-gradient(180deg, ${p => Array.isArray(p.buttonBackground) ? p.buttonBackground[0] : p.buttonBackground} 1%,${p => Array.isArray(p.buttonBackground) ? p.buttonBackground[1] : p.buttonBackground});
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="${p => Array.isArray(p.buttonBackground) ? p.buttonBackground[0] : p.buttonBackground}", endColorstr="${p => Array.isArray(p.buttonBackground) ? p.buttonBackground[0] : p.buttonBackground}", GradientType=0);
    text-transform: uppercase;
    font-size: 120%;
    font-weight: 700;
    letter-spacing: 2px;
    border: 0;
    position: relative;
    z-index: 1;
    text-align: center;
    text-decoration: none;
    border-radius: 2px;
    &:hover{ 
        transform: scale(1.1,1.1);
        transition: 0.3s;
     }
    
    @media (max-width: 995px){
        padding: 10px 25px;
        letter-spacing: 2px;
        width: 90%;
        max-width: 550px;
        margin-bottom: 20px;
        font-size: 3vw;
    }
    
    @media (max-width: 700px){
        font-size: 22px;
        font-weight: 600;
    }
`;

const PresentationScreen = ({
    backgroundImage = '',
    mobileBackgroundImage = null,
    mobileBreakpoint = 700,
    rasterImage = 'https://www.adulttime.com/6a74eeee2379cb1250de39df9689b47c.png',
    mobileRasterImage = null,
    alignment = 'left',
    verticalAlignment = 'center',
    title = '',
    titleColor = '#FFF',
    description = '',
    descriptionColor = '#FFF',
    buttonText = 'Start your trial',
    buttonTitle = 'Join Adult Time Now',
    buttonLink,
    buttonClickFunction = null,
    buttonColor = '#010118',
    buttonBackground = '#7E8FBB',
    imageQuality = '90'

}) => {

    const vWidth = window.innerWidth || document.documentElement.clientWidth;
    const [headerOffset, setHeaderOffset] = React.useState(0);
    const { trackAndRedirect } = useTracking();

    const handleClick = e => {
        e.stopPropagation();
        e.preventDefault();
        if (buttonClickFunction) {
            buttonClickFunction();
            return;
        }
        let payload = { component: 'PresentationScreen' };
        trackAndRedirect(payload, buttonLink);
    };

    let bgImage = backgroundImage;
    if (vWidth <= mobileBreakpoint && mobileBackgroundImage) bgImage = mobileBackgroundImage;
    let AlignedSection = SectionLeft;
    if (alignment === 'center') AlignedSection = SectionCentered;
    if (alignment === 'right') AlignedSection = SectionRight;

    return (
        <div ref={el => {
            if (!el) return;
            setHeaderOffset(el.getBoundingClientRect().y);
        }}>
            <PresentationWrapper backgroundImage={bgImage} imageQuality={imageQuality} headerOffset={headerOffset}>
                <PresentationRaster rasterImage={rasterImage} mobileRasterImage={mobileRasterImage} />
                <SectionWrapper alignment={alignment}>
                    <Section alignment={alignment} verticalAlignment={verticalAlignment}>
                        <Title dangerouslySetInnerHTML={{ __html: title }} titleColor={titleColor} />
                        <Description dangerouslySetInnerHTML={{ __html: description }} descriptionColor={descriptionColor} />
                        <Button title={buttonTitle} href="#" buttonColor={buttonColor} buttonBackground={buttonBackground} onClick={handleClick}>
                            {buttonText}
                        </Button>
                    </Section>
                </SectionWrapper>
            </PresentationWrapper>
        </div>

    );
};

export default PresentationScreen;
