import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import useTracking from '../Tracking/useTracking';
import "./SiteHeaderMediaBuy.css"

const SiteHeaderMediaBuyPropTypes = {
    headerProperties: PropTypes.shape({
        sitename: PropTypes.string,
        logo: PropTypes.string,
        loginLink: PropTypes.string,
        loginLinkTarget: PropTypes.string,
        logoHeight: PropTypes.string,
        loginText: PropTypes.string,
        joinText: PropTypes.string,
        background: PropTypes.string,
        loginColor: PropTypes.string,
        loginColorHover: PropTypes.string,
        loginBackgroundHover: PropTypes.string,
        loginBorderColor: PropTypes.string,
        loginBorderColorHover: PropTypes.string,
        joinColor: PropTypes.string,
        joinColorHover: PropTypes.string,
        joinBackground: PropTypes.string,
        joinBackgroundHover: PropTypes.string,
    }).isRequired,
}

const SiteHeaderMediaBuyDefaultProps = {
    headerProperties: {
        sitename: '',
        logo: '',
        loginLink: '',
        loginLinkTarget: '_self',
        logoHeight: '25px',
        loginText: 'Sign In',
        joinText: 'Join Now',
        background: '#000',
        loginColor: '#fff',
        loginColorHover: '#000',
        loginBackgroundHover: '#01f4e0',
        loginBorderColor: '#01f4e0',
        loginBorderColorHover: '#01f4e0',
        joinColor: '#000',
        joinColorHover: '#000',
        joinBackground: '#01f4e0',
        joinBackgroundHover: '#01f4e0',
    }
};


const HeaderContainer = styled.div`
    display: block;
    margin-top: 60px;

    @media (max-width: 600px){
        margin-top: 42px;
    }
`;

const Header = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    height: 60px;
    z-index: 10;
    background: ${props => props.background};
    display: flex;

    @media (max-width: 600px){
        height: 42px;
    }
`;

const HeaderWrapper = styled.div`
    max-width: 1700px;
    display: flex;
    flex-wrap: no-wrap;
    justify-content: space-between;
    padding: 0 10px;
    margin: auto;
    height: 100%;
    width: 100%;
    @media (max-width: 600px){
        padding: 0 16px;
    }
`;

const HeaderLeft = styled.div`
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-content: stretch;
    align-items: center;
    flex: 0 0 auto;
    height: 100%;
    
`;

const LogoLink = styled.a`
    height: ${p => p.logoHeight};
    margin-right: 10px;
    max-width: 250px;
    
    @media (max-width: 600px){
        height: 29px;
        max-width: 140px;
    }     
`;

const Logo = styled.img`
    display: block;
    height: 100%;
    width: 100%;
    object-fit: contain;
    object-position: left;
`;

const HeaderRight = styled.div`
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: no-wrap;
    align-content: stretch;
    flex: 0 0 auto;
    height: 100%;
`;


const HeaderLink = styled.a`
     height: 34px;
    text-transform: capitalize;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    line-height: 34px;
    vertical-align: middle;
    padding: 0 10px;
    border: 1px solid transparent;
    letter-spacing: 1px;
    text-decoration: none;
    font-family: "Hind", sans-serif;

    @media (max-width: 600px){
        font-size: 14px;
        height:31px;
        line-height: 31px;
        letter-spacing: initial;
    }
`

const JoinLink = styled(HeaderLink)`
    background: ${props => props.joinBackground};
    color: ${props => props.joinColor};
    margin-right: 8px;
    min-width: 202px;
   
    @media (hover: hover) {
        &:hover {
            background: ${props => props.joinBackgroundHover};
            color: ${props => props.joinColorHover};
        }        
    }

    @media (max-width: 600px){
        display: none;
    }
`

const LoginLink = styled(HeaderLink)`
    background: transparent;
    color: ${props => props.loginColor};
    border: 1px solid ${props => props.loginBorderColor};
    box-sizing: border-box;
    margin-right: 0;
    min-width: 59px;

    @media (hover: hover) {
        &:hover {
            background: ${props => props.loginBackgroundHover};
            color: ${props => props.loginColorHover};
            border-color: ${props => props.loginBorderColorHover};
        }        
    }
`
const SiteHeaderMediaBuy = ({ headerProperties }) => {
    headerProperties = { ...SiteHeaderMediaBuyDefaultProps.headerProperties, ...headerProperties };
    let {
        sitename,
        logo,
        loginLink,
        loginLinkTarget,
        logoHeight,
        loginText,
        joinText,
        background,
        loginColor,
        loginColorHover,
        loginBackgroundHover,
        loginBorderColor,
        loginBorderColorHover,
        joinColor,
        joinColorHover,
        joinBackground,
        joinBackgroundHover
    } = headerProperties;

    const { trackAndRedirect, sendChugEvent } = useTracking();

    const handleJoin = (elem) => e => {
        e.stopPropagation();
        e.preventDefault();
        let payload = { component: 'Header', element: elem };
        trackAndRedirect(payload);
    };

    const handleLogin = (elem) => e => {
        e.stopPropagation();
        e.preventDefault();
        let payload = { component: 'Header', element: elem };
        sendChugEvent('click', payload);
        if (loginLinkTarget === '_blank') {
            window.open(loginLink, loginLinkTarget);
        }
        else {
            window.location.href = loginLink;
        }
    };

    return (
        <HeaderContainer>
            <Header background={background}>
                <HeaderWrapper>
                    <HeaderLeft>
                        <LogoLink logoHeight={logoHeight} onClick={handleJoin('logo')} href='#' >
                            <Logo src={logo} alt={sitename} />
                        </LogoLink>
                    </HeaderLeft>
                    <HeaderRight>
                        <JoinLink
                            className="lp-join-link"
                            joinBackground={joinBackground}
                            joinColor={joinColor}
                            joinColorHover={joinColorHover}
                            joinBackgroundHover={joinBackgroundHover}
                            onClick={handleJoin('joinButton')} href='#'>
                            {joinText}
                        </JoinLink>
                        <LoginLink
                            onClick={handleLogin('loginButton')}
                            loginColor={loginColor}
                            loginColorHover={loginColorHover}
                            loginBackgroundHover={loginBackgroundHover}
                            loginBorderColor={loginBorderColor}
                            loginBorderColorHover={loginBorderColorHover}
                            href={loginLink}>
                            {loginText}
                        </LoginLink>
                    </HeaderRight>
                </HeaderWrapper>
            </Header>
        </HeaderContainer>
    );
};

SiteHeaderMediaBuy.propTypes = SiteHeaderMediaBuyPropTypes;
SiteHeaderMediaBuy.defaultProps = SiteHeaderMediaBuyDefaultProps;
export default SiteHeaderMediaBuy;
