import React from 'react';
import { canUseWebP} from '../../lib/utils';

const Image = (props) => {
  let { ratio = '16:9', src, maxWidth = 480 } = props;
  let widthRatio = parseInt(ratio.split(':')[0], 10) / parseInt(ratio.split(':')[1], 10);
  let doubleWidth = maxWidth * 2 > 480 ? 480 : maxWidth * 2;
  let tripleWidth = maxWidth * 3 > 480 ? 480 : maxWidth * 3;
  let srcSet, finalSrc;

  let filteredProps = { ...props };
  delete(filteredProps.maxWidth);
  delete(filteredProps.ratio);
  delete(filteredProps.innerRef);

  //If link as dynamic parameters we return a regular image
  if (src.indexOf("?") !== -1) return (<img alt="img" {...filteredProps} />);

  //If Gamma Transform service we modify src
  if (src.indexOf("transform.") !== -1) {
    finalSrc = `${src}?width=${maxWidth}&height=${Math.round(maxWidth/widthRatio)}&enlarge=true${canUseWebP()?'&format=webp':''}`;
    //We only set srcSet if we try to get smaller images then 480
    if (maxWidth < 480) {
      srcSet = `${src}?width=${doubleWidth}&height=${Math.round(doubleWidth/widthRatio)}&enlarge=true${canUseWebP()?'&format=webp':''} 2x`;
      if (tripleWidth > doubleWidth) srcSet = `${srcSet},${src}?width=${tripleWidth}&height=${Math.round(tripleWidth/widthRatio)}&enlarge=true${canUseWebP()?'&format=webp':''} 3x`;
    }


  }
  //If Contentful, we modify to accomodate width
  else if (src.indexOf("images.ctfassets.") !== -1) {
    //We use Gamma CDN to save cost
    src = (src || "").replace("images.ctfassets.net", "ctf-images.gammacdn.com");
    finalSrc = `${src}?w=${maxWidth}${canUseWebP()?'&fm=webp':''}`;
    if (maxWidth < 480) {
      srcSet = `${src}?w=${doubleWidth}${canUseWebP()?'&fm=webp':''} 2x`;
      if (tripleWidth > doubleWidth) srcSet = `${srcSet},${src}?w=${doubleWidth}${canUseWebP()?'&fm=webp':''} 3x`;
    }


  }


  return (<img alt="img" {...filteredProps} ref={props.innerRef} src={finalSrc} srcSet={srcSet}/>);

};

export default Image;
