import React, { lazy, Suspense } from 'react';
import { useParams} from 'react-router-dom';
import SiteData from '../../components/survey/SiteData';
//import SurveyPageLayout /* webpackChunkName:"features" */ from '../../components/survey/SurveyPageLayout';

const SurveyPageLayout = lazy(() =>
	import ( /* webpackChunkName:"survey" */ '../../components/survey/SurveyPageLayout'));

    const FallBack = () => {
        return <div>Loading...</div>;
    };
    const SuspenseLoader = ({ children }) => {
    
        return <Suspense fallback={<FallBack/>}>{children}</Suspense>;
    };

const SurveyPage = ({domain}) => {
    let {sitetag} =  useParams() || {};

	return (
        <div style={{height:'100vh'}}>
            <SuspenseLoader>
            <SiteData domain={domain} sitetag={sitetag}>
                <SurveyPageLayout>
                </SurveyPageLayout>
            </SiteData>
            </SuspenseLoader>
        </div>
        );

};
export
default SurveyPage;
