import React from 'react';
import styled from 'styled-components';
import useTracking from '../Tracking/useTracking';

const SocialIconsWrapper = styled.div `
    padding: 20px 20px 40px;
`;

const Logo = styled.a `
    background: #fff;
    background-image: url( ${p => p.src});
    font-size: 0;
    transition: background .3s;
    display: inline-block;
    width: 50px;
    height: 50px;
    margin: 0 11px;
    border-radius: 8px;
    background-repeat: no-repeat;
    background-position: 50%;
    
    @media (max-width: 995px){
        background-size: 38px;
        width: 50px;
        height: 50px;
        margin: 0 15px;
    }
`;

const SocialIcons = ({
	instagramSrc = 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDIzLjAuMiwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiCgkgdmlld0JveD0iMCAwIDE2OS4xIDE2OS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxNjkuMSAxNjkuMTsiIHhtbDpzcGFjZT0icHJlc2VydmUiPgo8cGF0aCBkPSJNMTEzLjEsODQuNWMwLDE1LjctMTIuOCwyOC42LTI4LjYsMjguNlM1NiwxMDAuMyw1Niw4NC41QzU2LDY4LjgsNjguOCw1Niw4NC41LDU2UzExMy4xLDY4LjgsMTEzLjEsODQuNXogTTE1NCwxMjIuNAoJTDE1NCwxMjIuNGMwLDE3LjQtMTQuMiwzMS42LTMxLjYsMzEuNkg0Ni43QzI5LjIsMTU0LDE1LDEzOS44LDE1LDEyMi40VjQ2LjdDMTUsMjkuMiwyOS4yLDE1LDQ2LjcsMTVoNzUuNwoJYzE3LjQsMCwzMS42LDE0LjIsMzEuNiwzMS42VjEyMi40eiBNMTI4LjEsODQuNWMwLTI0LTE5LjUtNDMuNS00My41LTQzLjVTNDEsNjAuNSw0MSw4NC41YzAsMjQsMTkuNSw0My41LDQzLjUsNDMuNQoJUzEyOC4xLDEwOC41LDEyOC4xLDg0LjV6IE0xNDAuOSwzOS4zYzAtMi45LTEuMi01LjctMy4yLTcuOGMtMi0yLTQuOS0zLjItNy44LTMuMmMtMi45LDAtNS43LDEuMi03LjgsMy4yYy0yLjEsMi0zLjIsNC45LTMuMiw3LjgKCWMwLDIuOSwxLjIsNS43LDMuMiw3LjhjMiwyLDQuOSwzLjIsNy44LDMuMmMyLjksMCw1LjctMS4yLDcuOC0zLjJDMTM5LjcsNDUsMTQwLjksNDIuMiwxNDAuOSwzOS4zeiIvPgo8L3N2Zz4K',
	twitterSrc = 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDIzLjAuMiwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkNhcGFfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiCgkgdmlld0JveD0iMCAwIDYxMiA2MTIiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDYxMiA2MTI7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4KCS5zdDB7ZmlsbDojMDEwMDAyO30KPC9zdHlsZT4KPGc+Cgk8Zz4KCQk8cGF0aCBjbGFzcz0ic3QwIiBkPSJNNTk1LjEsMTI2LjdjLTIxLjMsOS40LTQ0LjEsMTUuOC02OC4xLDE4LjdjMjQuNS0xNC43LDQzLjItMzcuOSw1Mi4xLTY1LjZjLTIzLDEzLjYtNDguMywyMy40LTc1LjQsMjguOAoJCQljLTIxLjYtMjMuMS01Mi40LTM3LjUtODYuNi0zNy41Yy02NS41LDAtMTE4LjYsNTMuMS0xMTguNiwxMTguNmMwLDkuMywxLDE4LjQsMy4xLDI3Yy05OC42LTUtMTg2LTUyLjItMjQ0LjUtMTIzLjkKCQkJYy0xMC4yLDE3LjUtMTYsMzcuOS0xNiw1OS42YzAsNDEuMiwyMSw3Ny41LDUyLjgsOTguN2MtMTkuNC0wLjYtMzcuNy02LTUzLjctMTQuOXYxLjVjMCw1Ny40LDQwLjksMTA1LjQsOTUuMSwxMTYuMwoJCQljLTkuOSwyLjctMjAuNCw0LjItMzEuMyw0LjJjLTcuNywwLTE1LjEtMC44LTIyLjMtMi4yYzE1LjEsNDcuMiw1OC45LDgxLjQsMTEwLjgsODIuNGMtNDAuNiwzMS44LTkxLjcsNTAuNy0xNDcuMyw1MC43CgkJCWMtOS42LDAtMTktMC42LTI4LjMtMS42YzUyLjUsMzMuNywxMTQuOCw1My40LDE4MS44LDUzLjRjMjE4LjIsMCwzMzcuNS0xODAuNywzMzcuNS0zMzcuNWwtMC40LTE1LjQKCQkJQzU1OS4xLDE3MS40LDU3OS4yLDE1MC41LDU5NS4xLDEyNi43eiIvPgoJPC9nPgo8L2c+Cjwvc3ZnPgo=',
	youtubeSrc = 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDIzLjAuMiwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkNhcGFfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiCgkgdmlld0JveD0iMCAwIDUxMiA1MTIiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDUxMiA1MTI7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHBhdGggZD0iTTU0LjIsMTRsNDAxLjcsMjQxTDU0LjIsNDk2VjE0eiIvPgo8L3N2Zz4K',
	twitterLink = 'https: //twitter.com/Adulttimecom/',
	instagramLink = 'https://www.instagram.com/adulttimecom/',
	youtubeLink = 'https://www.youtube.com/AdultTimeCom',
	twitterTitle = '',
	instagramTitle = '',
	youtubeTitle = ''

}) => {
	const { trackAndRedirect } = useTracking();

	const handleClick = e => {
		e.stopPropagation();
		e.preventDefault();
		let payload = { component: 'SocialIcons', href: e.target.href };
		trackAndRedirect(payload, e.target.href, '_blank');
	};

	return (
		<SocialIconsWrapper>
			{twitterLink &&<Logo href={twitterLink} title={twitterTitle} onClick={handleClick} src={twitterSrc}>Twitter</Logo>}
			{instagramLink &&<Logo href={instagramLink} title={instagramTitle} onClick={handleClick} src={instagramSrc} style={{ borderRadius: '15px' }}>Instagram</Logo>}
			{youtubeLink &&<Logo  href={youtubeLink} title={youtubeTitle} onClick={handleClick} src={youtubeSrc} style={{ backgroundSize: '25px' }}>Youtube</Logo>}
		</SocialIconsWrapper>
	);
};

export default SocialIcons;
