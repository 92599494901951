import { useEffect, useCallback, useRef } from 'react';


const FlashingTab = props => {
    const { flashingInterval, flashingTitle, flashingIcon } = props;

    const defaultTitle = useRef({});
    const originalIcon = useRef({});
    const intervalFunction=useRef({});;

    let iconLink = document.querySelector("link[rel*='icon']");
    originalIcon.current = iconLink && iconLink.href;

    const flashingFunction = () => {
        let iconLink = document.querySelector("link[rel*='icon']");
        document.title = document.title === flashingTitle ? defaultTitle.current[document.URL] : flashingTitle;
        if (flashingIcon && iconLink && originalIcon){
            iconLink.href = iconLink.href === flashingIcon ? originalIcon.current : flashingIcon;
        }
    };

    const onFocusTab = useCallback(() => {
        clearInterval(intervalFunction.current);
        document.title = defaultTitle.current[document.URL];
        let iconLink = document.querySelector("link[rel*='icon']");
        if (flashingIcon && iconLink && originalIcon.current) iconLink.href = originalIcon.current;
        window.removeEventListener('focus', onFocusTab);
    },[flashingIcon,]);

    const onBlurTab = useCallback(() => {
        if (defaultTitle.current[document.URL] == null) {
            defaultTitle.current[document.URL] = document.title;
        }
        
        intervalFunction.current = setInterval(flashingFunction, flashingInterval);
        window.addEventListener('focus', onFocusTab);
    }, [flashingInterval,onFocusTab]);



    useEffect(() => {
        window.addEventListener('blur', onBlurTab);

        return () => {
            window.removeEventListener('blur', onBlurTab);
        };
    });

    
    return null;
};

FlashingTab.displayName = 'FlashingTab';

FlashingTab.defaultProps = {
    flashingInterval: 1000,
    flashingTitle: '',
    flashingIcon: '',
};



export default FlashingTab;