/* eslint-disable no-param-reassign */
import qs from 'query-string';
import uuid from 'uuidv4';
import { readCookie, getTrackingObject } from '../lib/utils';

import { LOAD_TRACKCOOKIE_DATA } from '../actions';

const args = qs.parse(window.location.search);
let sessionId = uuid();

const initialState = {
  adv_id: args.ad || args.adv_id || null,
  campaign: args.campaign || null,
  acampaign: args.acampaign || null,
  gallery_id: args.gallery_id || `lp_${sessionId}`,
  gsub_id: args.gsub_id || null,
  skin_id: args.skin_id || null,
  productgroup_id: args.pg || null,
  subprogram_id: args.su || args.subprogram_id || null,
  ga_user_id: readCookie('_ga'),
  session_id: sessionId,
  oneclick: args['1click'] || null,
  oneclick_email_address: args['1click_email_address'] || null,
  oneclick_custom_code_id: args['1click_custom_code_id'] || null,
  oneclick_crypted_string: args['1click_crypted_string'] || null,
  rtb_click_id: args['rtb_click_id'] || null

};

const trackingReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_TRACKCOOKIE_DATA:
      {
        return { ...state, trackCookie: getTrackingObject() };
      }
    default:
      return state;

  }
};

export default trackingReducer;
