import React from 'react';
import styled from 'styled-components';
import useTracking from '../Tracking/useTracking';
import VideoLazy from '../common/VideoLazy';
import ImageLazy from '../common/ImageLazy';
import { getOptimizedImageUrl } from '../../lib/utils';
import VolumeMuteIcon from '@mui/icons-material/VolumeMute';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';


const vWidth = window.innerWidth || document.documentElement.clientWidth;
const VideoHeaderWrapper = styled.div `
  cursor: pointer;
  width: 100%;
  padding-top:${p => p.paddingTop};
  padding-bottom:${p => p.paddingBottom};
  `;


const MuteWrapper= styled.div`
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: end;  
  justify-content: center;
  line-height: 30px;
`;
const MuteButton= styled.button`
 display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  color: white;
  background: black;
  border: 0;
  padding: 4px 8px;

`;
const MuteComponent = ({isMuted,handleMute}) => {
  return (
    <MuteWrapper>
      <MuteButton onClick={handleMute}>
      {isMuted ? "Unmute" : "Mute"}
        {isMuted ? <VolumeMuteIcon/> : <VolumeOffIcon/>}
      </MuteButton>
    </MuteWrapper>
  );
};

const VideoHeader = ({ imgSrc, videoSrc, mobileImgSrc, mobileVideoSrc, mobileBreakpoint = 800, paddingTop = '20px', paddingBottom = '20px',handleClickFunction,
enableUnmute=false }) => {
  const { trackAndRedirect } = useTracking();
  const [isMuted, setIsMuted] = React.useState(true);

  const handleClick = e => {
    e.stopPropagation();
    e.preventDefault();
    if(handleClickFunction){
      handleClickFunction();
      return;
    }
    let payload = { component: 'VideoHeader' };
    trackAndRedirect(payload);
  };
  const handleMute = e => {
    e.stopPropagation();
    e.preventDefault();
    setIsMuted(!isMuted);
  };
  let imgStyle = {
    display: 'block',
    width: '100%',
    maxWidth: '1920px',
    margin: '0 auto',
  };
  let poster = imgSrc;
  let src = videoSrc;
  const isMobile = vWidth <= mobileBreakpoint;

  if (isMobile) {
    //This is a way to not display component 
    if ((mobileImgSrc === null && mobileVideoSrc === "") || (mobileImgSrc === null && mobileVideoSrc === "") || (mobileVideoSrc === "" && mobileImgSrc === "")) {
      return null;
    }
    //if the component only display image
    if (mobileImgSrc && !mobileVideoSrc) return <VideoHeaderWrapper onClick={handleClick}><ImageLazy src={mobileImgSrc} style={imgStyle}/></VideoHeaderWrapper>;
    if (mobileVideoSrc) src = mobileVideoSrc;
    if (mobileImgSrc) poster = mobileImgSrc;
  }


  return (
    <VideoHeaderWrapper  onClick={handleClick} paddingTop={paddingTop} paddingBottom={paddingBottom}>
      <VideoLazy  src={src} poster={getOptimizedImageUrl(poster)}  muted={isMuted} styleVideo={imgStyle}/>
        {enableUnmute && <MuteComponent isMuted={isMuted} handleMute={handleMute}/>}
    </VideoHeaderWrapper>
  );

};
export default VideoHeader;
