/* eslint-disable no-param-reassign */
import qs from 'query-string';

import {
  UPDATE_ROUTE_PARAMS,
  LOAD_LP_DATA,
  UPDATE_BANNERS_DATA
}
from '../actions';
const args = qs.parse(window.location.search);

const initialState = {
  draftMode: args.draftmode ? true : false,
  analyticsMode: args.analytics ? true : false
};

const configReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_ROUTE_PARAMS:
      {
        const { slug, category, sitetag, domain } = action.payload;
        return { ...state, slug, category, sitetag, domain };
      }
    case LOAD_LP_DATA:
      {
        return { ...state, lpdata: action.payload, sitetag: action.payload.sitetag };
      }
    case UPDATE_BANNERS_DATA:
      {
        let lpdata = state.lpdata;
        lpdata.banners = action.payload;
        return { ...state, lpdata };
      }
    default:
      return state;

  }
};

export default configReducer;
