/* eslint-disable no-param-reassign */

import { LOAD_SCENEGRID_DATA, LOAD_ANALYTICS_DATA } from '../actions';

const initialState = { sceneGridData: [], analyticsData: [] };

const sceneGridReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_SCENEGRID_DATA:
      {
        return { ...state, sceneGridData: action.payload };
      }
    case LOAD_ANALYTICS_DATA:
      {
        return { ...state, analyticsData: action.payload };
      }
    default:
      return state;
  }
};

export default sceneGridReducer;
