import React, { useEffect } from 'react';
import { useAsync } from 'react-use';
import { useSelector, useDispatch } from 'react-redux';
import { loadLandingPage } from '../lib/algolia.js';
import { loadLpData } from '../actions';
import { Helmet } from "react-helmet";


const LandingPageData = ({ domain, sitetag, slug, category, children }) => {
  const { draftMode } = useSelector(state => state.config);

  const dispatch = useDispatch();

  const data=useAsync(() =>{
    if(window.lpData && !draftMode) return Promise.resolve(window.lpData);
    else return loadLandingPage({ domain: domain, sitetag: sitetag, slug: slug, draft: draftMode }, [sitetag, domain, slug, draftMode, category]);
    });

  useEffect(() => {
    if (data.value && !data.loading) {
      dispatch(loadLpData(data.value));
    }
  }, [data.value, data.loading, dispatch]);

  if (domain == null && sitetag == null) return null;
  
  if (data.loading === true) return null;
  else if (data.error) {
    //force redirection
    let root = `https://${window.location.hostname}/${window.location.search}`;
    if (!draftMode && window.location.href !== root) window.location.href = root;
    else return <div>		<Helmet title="Unable to load" meta={[ {
                "name": "robots",
                "content": "noindex"
            }]}/>Unable to load</div>;
  }
  else if (!data.value) return null;
  //else if (!lpdata) return null;
  
  return children;
};

export default LandingPageData;
