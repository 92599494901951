import { mergeDeep } from './utils.js';
const algoliasearch = require('algoliasearch/lite');
// Note : Keys only used for this project
const client = algoliasearch('WP8Q3HDHDZ', '269ee080941929e3829f27dc3a7fd46b');
const clientScene = algoliasearch('TSMKFA364Q', '1b53476a9d2ab94737558dab43d30856');

export const loadLandingPage = async({ sitetag = null, domain = null, slug = null, draft = false }) => {
  if (!sitetag && !slug && !domain) return null;
  const queries = [{
      indexName: draft ? 'draft_sites' : 'sites',
      query: '',
      params: {
        facetFilters: sitetag ? [`sitetag:${sitetag}`] : [`domain:${domain}`],
        responseFields: ['index', 'hits'],
        attributesToHighlight: [],
        attributesToRetrieve: [
          'sitetag',
          'domain',
          'segment',
          'landingpageProperties',
          'landingpageScenesFilter',
        ]
      }
    },
    {
      indexName: draft ? 'draft_landingpages' : 'landingpages',
      query: '',
      params: {
        facetFilters: [`slug:${slug}`, sitetag ? `sites.sitetag:${sitetag}` : `sites.domain:${domain}`],
        responseFields: ['index', 'hits'],
        attributesToHighlight: [],
        attributesToRetrieve: [
          'properties',
          'slug',
          'numberOfScenes',
          'scenesFilter',
          'scenesSorting',
          'scenesOverride',
          'videoAutoPlayPositions',
          'banners'
        ]
      }
    }
  ];

  return client.search(queries).then(async res => {
    let site = res['results'][0]['hits'][0];
    //console.log({ sitetag, domain, slug, draft });
    let lp = res['results'][1]['hits'][0];
    if (site && !lp && slug === (domain || sitetag)) {
      //try fallback if no lp and root domain
      //console.log("fallback");
      let fallbackQuery = queries[1];
      let fallbackSlug = (site['landingpageProperties'] || {})['fallbackSlug'] || "default";
      fallbackQuery['params']['facetFilters'] = [`slug:${fallbackSlug}`];
      let fallback = await client.search([fallbackQuery]);
      lp = fallback['results'][0]['hits'][0];
    }
    //if (!lp) throw new Error("Nothing to load");
    let result = Object.assign({}, site, lp);

    result.properties = mergeDeep(site.landingpageProperties || {}, result.properties || {});
    result.siteScenesFilter = site.landingpageScenesFilter;
    delete(result.objectID);
    delete(result.landingpageProperties);
    delete(result.landingpageScenesFilter);

    return Promise.resolve(result || {});
  });
};

//Load only siteData to use by Survey
export const getSiteData = async({ sitetag = null, domain = null, draft = false }) => {
  if (!sitetag && !domain) return null;
  const queries = [{
      indexName: draft ? 'draft_sites' : 'sites',
      query: '',
      params: {
        facetFilters: sitetag ? [`sitetag:${sitetag}`] : [`domain:${domain}`],
        responseFields: ['index', 'hits'],
        attributesToHighlight: [],
        attributesToRetrieve: [
          'sitetag',
          'domain',
          'segment',
          'landingpageProperties',
          'landingpageScenesFilter',
        ]
      }
    }
  ];

  return client.search(queries).then(async res => {
    let result={};
    result['properties'] = res['results'][0]['hits'][0]['landingpageProperties'];
    delete(result.objectID);
    return Promise.resolve(result || {});
  });
};

export const loadScenes = (segment, filters, numItems, index = 'all_scenes', additionalScenes) => {
  if (numItems === 0) return [];

  const queries = [{
    indexName: index,
    query: '',
    params: {
      filters: filters,
      facetFilters: [`segment:${segment}`],
      responseFields: ['index', 'hits'],
      attributesToHighlight: [],
      attributesToRetrieve: ['clip_id', 'title', 'release_date', 'sitename_pretty', 'pictures'],
      hitsPerPage: numItems
    }
  }];

  if (additionalScenes.length) queries.push({
    indexName: 'all_scenes',
    query: '',
    params: {
      filters: `(${(additionalScenes||[]).map(i=>`clip_id:${i}`).join(' OR ')})`,
      facetFilters: [`segment:${segment}`],
      responseFields: ['index', 'hits'],
      attributesToHighlight: [],
      attributesToRetrieve: ['clip_id', 'title', 'release_date', 'sitename_pretty', 'pictures'],
      hitsPerPage: numItems
    }

  });


  return clientScene.search(queries).then(res => {
    //console.log(res['results'][0]['hits'][0])
    let result = res['results'][0]['hits'];
    if (additionalScenes.length) result = result.concat(res['results'][1]['hits']);
    //console.log(result);
    return result.map(item => {
      delete(item.objectID);
      return item;
    }) || {};
  });
};

const lib={ loadLandingPage, loadScenes };
export default lib