export const UPDATE_ROUTE_PARAMS = 'UPDATE_ROUTE_PARAMS';
export const SEND_CHUG_EVENT = 'SEND_CHUG_EVENT';
export const LOAD_LP_DATA = 'LOAD_LP_DATA';
export const UPDATE_BANNERS_DATA = 'UPDATE_BANNERS_DATA';
export const LOAD_ANALYTICS_DATA = 'LOAD_ANALYTICS_DATA';
export const LOAD_SCENEGRID_DATA = 'LOAD_SCENEGRID_DATA';
export const LOAD_TRACKCOOKIE_DATA = 'LOAD_TRACKCOOKIE_DATA';


export function updateRouteParams(params) {
  return (dispatch) => dispatch({
    type: UPDATE_ROUTE_PARAMS,
    payload: params
  });
}

export function loadLpData(params) {
  return (dispatch) => dispatch({
    type: LOAD_LP_DATA,
    payload: params
  });
}

export function loadSceneGridData(params) {
  return (dispatch) => dispatch({
    type: LOAD_SCENEGRID_DATA,
    payload: params
  });
}

export function loadAnalyticsData(params) {
  return (dispatch) => dispatch({
    type: LOAD_ANALYTICS_DATA,
    payload: params
  });
}

export function updateBannerData(params) {
  return (dispatch) => dispatch({
    type: UPDATE_BANNERS_DATA,
    payload: params
  });
}

export function loadTrackCookieData(params) {
  return (dispatch) => dispatch({
    type: LOAD_TRACKCOOKIE_DATA,
    payload: params
  });
}
