import React, { lazy, Suspense } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import VideoHeader from '../../components/VideoHeader/VideoHeader';
import PresentationScreen from '../../components/PresentationScreen/PresentationScreen';

import TextArea from '../../components/TextArea/TextArea';
import SocialIcons from '../../components/SocialIcons/SocialIcons';

const SitesLogoList = lazy(() =>
	import( /* webpackChunkName:"features" */ '../../components/SitesLogoList/SitesLogoList'));

const FeaturedPublications = lazy(() =>
	import( /* webpackChunkName:"features" */ '../../components/FeaturedPublications/FeaturedPublications'));

const FooterLinks = lazy(() =>
	import( /* webpackChunkName:"features" */ '../../components/FooterLinks/FooterLinks'));

const SceneGrid = lazy(() =>
	import( /* webpackChunkName:"scenegrid" */
		'../../components/SceneGrid/SceneGrid'));

const FakePagination = lazy(() =>
	import( /* webpackChunkName:"scenegrid" */ '../../components/FakePagination/FakePagination'));

const PosterCarousel = lazy(() =>
	import( /* webpackChunkName:"slick" */ '../../components/PosterCarousel/PosterCarousel'));

const TestimonialSlider = lazy(() =>
	import( /* webpackChunkName:"slick" */ '../../components/TestimonialSlider/TestimonialSlider'));

const EmailGrabberWithQuotes = lazy(() =>
	import( /* webpackChunkName:"slick" */ '../../components/EmailGrabberWithQuotes/EmailGrabberWithQuotes'));

const BannerSliderWithCTA = lazy(() =>
	import( /* webpackChunkName:"slick" */ '../../components/BannerSliderWithCTA/BannerSliderWithCTA'));

const VideoPresentationScreen = lazy(() =>
	import( /* webpackChunkName:"slick" */ '../../components/VideoPresentationScreen/VideoPresentationScreen'));

const HomeWrapper = styled.div`
	padding-top: 0;
	min-height: 100%;
	width: 100%;
	text-align: center;
	background: ${p => p.background};
  @media (max-width: 700px){
		padding-top: 8px;
	}
`;

const FallBack = () => {
	return <div>Loading...</div>;
};
const SuspenseLoader = ({ children }) => {

	return <Suspense fallback={<FallBack />}>{children}</Suspense>;
};

const Home = ({ category, match }) => {
	const landingPageData = useSelector(state => state.config.lpdata) || {};

	let components = ((landingPageData.properties || {}).body || {}).components;
	let background = ((landingPageData.properties || {}).body || {}).background;
	let p = (((landingPageData.properties || {}).body || {}).pagination || {});
	let videoHeader = (((landingPageData.properties || {}).body || {}).videoHeader || null);

	const RenderComponent = (c, pos) => {
		let key = Object.keys(c)[0];
		c[key].key = pos;
		let component = null;
		switch (key) {
			case 'VideoHeader':
				component = <VideoHeader {...c[key]} />;
				break;
			case 'PresentationScreen':
				component = <PresentationScreen {...c[key]} />;
				break;
			case 'SitesLogoList':
				component = <SitesLogoList {...c[key]} />;
				break;
			case 'FeaturedPublications':
				component = <FeaturedPublications {...c[key]} />;
				break;
			case 'TextArea':
				component = <TextArea {...c[key]} />;
				break;
			case 'SocialIcons':
				component = <SocialIcons {...c[key]} />;
				break;
			case 'PosterCarousel':
				component = <PosterCarousel {...c[key]} />;
				break;
			case 'EmailGrabberWithQuotes':
				component = <EmailGrabberWithQuotes {...c[key]} />;
				break;
			case 'SceneGrid':
				component = <SceneGrid category={category} {...c[key]} />;
				break;
			case 'FakePagination':
				component = <FakePagination textColor={p.textColor} borderColor={p.borderColor} hoverColor={p.hoverColor} {...c[key]} />;
				break;
			case 'TestimonialSlider':
				component = <TestimonialSlider {...c[key]} />;
				break;
			case 'BannerSliderWithCTA':
				component = <BannerSliderWithCTA {...c[key]} />;
				break;
			case 'FooterLinks':			
				component = <FooterLinks textColor={p.textColor} hoverColor={p.hoverColor} {...c[key]} />;
				break;
			case 'VideoPresentationScreen':
				component = <VideoPresentationScreen {...c[key]} />;
				break;
			default:
				return null;
		}
		return <SuspenseLoader key={pos}>{component}</SuspenseLoader>;
	};

	//return <HomeWrapper background={background}><PosterCarousel/></HomeWrapper>
	if (!components) {
		components = [];
		if (videoHeader) components.push({ VideoHeader: videoHeader });
		components.push({ SceneGrid: {} });
		components.push({ FakePagination: {} });
		components.push({ FooterLinks: {} });
	} else {
		const hasFooterLinks = components.some(c => Object.keys(c)[0] === 'FooterLinks');
		if (!hasFooterLinks) {
			components.push({ FooterLinks: {} });
		}
	}


	return (
		<HomeWrapper background={background}>
			{components.map((c, pos) => RenderComponent(c, pos))}
		</HomeWrapper>);

};
export
	default Home;
