import React, { lazy, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Helmet } from "react-helmet";

import useTracking from './Tracking/useTracking';
import TrackingPixel from './Tracking/TrackingPixel';
import TagManager from 'react-gtm-module';
import { setCrossSubdomainCookie, fontLoader, loadPromoMessages, getShortlanguage, dedupeArray, readCookie } from '../lib/utils';
import Home from '../routes/home';
import SiteHeader from './SiteHeader/SiteHeader';
import ATSiteHeader from './SiteHeader/ATSiteHeader';
import SiteHeaderMediaBuy from './SiteHeader/SiteHeaderMediaBuy';
import LegalFooter from './LegalFooter/LegalFooter';
import FlashingTab from './FlashingTab';

const WarningOverlay = lazy(() =>
  import( /* webpackChunkName:"“warningOverlay”" */ './WarningOverlay/WarningOverlay'));

const CustomHeader = ({ header }) => {
  if (header.logo) {
    header.logo = header.logo.replace("images.ctfassets.net", "ctf-images.gammacdn.com");
  }
  switch (header.componentType) {
    case 'ATSiteHeader':
      return <ATSiteHeader headerProperties={header} />;
    case 'SiteHeaderMediaBuy':
      return <SiteHeaderMediaBuy headerProperties={header} />;
    default:
      return <SiteHeader headerProperties={header} />;
  }
};

const SetFontFamily = ({ fontFamily }) => {
  if (fontFamily) {
    if (fontFamily.indexOf("Hind") !== -1) fontLoader('Hind');
    document.body.style.fontFamily = fontFamily;
  }
  return null;
};

const Layout = () => {
  const { sitetag, lpdata, slug, category } = useSelector(state => state.config);
  const advId = useSelector(state => state?.tracking?.adv_id);
  const [isRedirecting, setIsRedirecting] = useState(false); //To prevent rendering while redirecting
  const p = (lpdata || {}).properties || {};
  const body = p.body || {};
  const warningOverlay = body.warningOverlay || {};
  const { enable: enableWarningOverlay = true } = warningOverlay;
  const helmet = p.helmet || {};
  const { sendChugEvent, trackUrl, trackAndRedirect } = useTracking();

  const isSfw = readCookie('sfw') === '1';

  useEffect(() => {
    if (lpdata) {
      //We redirect if language redirect configured
      if (lpdata.properties.forceLanguageRedirect) {
        let redirectUrl = lpdata.properties.forceLanguageRedirect[getShortlanguage()];
        if (redirectUrl) {
          setIsRedirecting(true);
          window.location.href = `${redirectUrl}${window.location.search}`;
        }
      }

      //We redirect to join page if sfw cookie is set
      if (isSfw) {
        setIsRedirecting(true);
        trackAndRedirect({ sitetag, slug }, null, null, null, 'sfwredirect', true);
      }

      sendChugEvent('impression', {});
      setCrossSubdomainCookie('COOKIE_ACKNOWLEDGED', 'dismiss');
      setCrossSubdomainCookie('landingpage', `lp.${slug}`, 1);

      if (p.gtmId) {
        TagManager.initialize({ gtmId: p.gtmId });
      }
      if (p.promoMessages) {
        setTimeout(function () {
          loadPromoMessages(sitetag);
        }, 800);
      }
    }
  }, [lpdata, p.gtmId, p.promoMessages, sendChugEvent, sitetag, slug, trackAndRedirect, isSfw]);

  if (!lpdata || isRedirecting) {
    if (isSfw && trackUrl) return <TrackingPixel url={trackUrl} />
    return null;
  };

  if (Array.isArray(helmet.link)) {
    helmet.link.forEach((link, index) => {
      link.href = link.href.replace("images.ctfassets.net", "ctf-images.gammacdn.com");
    });
  }

  const isOrganicTraffic = !advId || Number(advId) === 1000;
  const shouldDisplayWarning = isOrganicTraffic && enableWarningOverlay;


  return (
    <div id="app">
      <Helmet title={helmet.title} link={helmet.link} meta={dedupeArray(helmet.meta, "name", "content")} />
      {p.flashingTab && <FlashingTab flashingTitle={p.flashingTab.flashingTitle} flashingIcon={p.flashingTab.flashingIcon} flashingInterval={p.flashingTab.flashingInterval} />}
      <SetFontFamily fontFamily={p.fontFamily} />
      {trackUrl && <TrackingPixel url={trackUrl} />}
      <CustomHeader header={p.header} />
      {shouldDisplayWarning && <WarningOverlay {...warningOverlay} />}
      <Home category={category} />

      <LegalFooter backgroundColor={body.background} {...body.legalFooter} />
    </div>
  );
};

export default Layout;
