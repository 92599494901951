import { combineReducers } from 'redux';
import configReducer from './configReducer';
import sceneGridReducer from './sceneGridReducer';
import trackingReducer from './trackingReducer';

const rootReducer = combineReducers({
  config: configReducer,
  sceneGrid: sceneGridReducer,
  tracking: trackingReducer,
});

export default rootReducer;
