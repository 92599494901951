import React from 'react';
import { Provider } from 'react-redux';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import App from './App';
import /* webpackPrefetch: true */ * as serviceWorker from './serviceWorker';
import { configureStore } from './store/configureStore';

const store = configureStore();

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <Provider store={store}>
        <BrowserRouter>
    <App />
    </BrowserRouter>
  </Provider>
);

serviceWorker.register();
