import React, { Component } from "react";
import PropTypes from "prop-types";

class Video extends Component {
  handleEvent = event => {
    const { handleEvent = f => f } = this.props;
    handleEvent(event);
  };

  render() {
    let { src, styleVideo, poster, innerRef, autoPlay = true, lazyplay = false, loop=true, muted=true } = this.props;

    //We use GammaCDN to save costs
    poster = (poster || "").replace("images.ctfassets.net", "ctf-images.gammacdn.com");
    src = (src || "").replace("videos.ctfassets.net", "ctf-videos.gammacdn.com");

    return (
      <video
        key={lazyplay}
        poster={poster}
        ref={innerRef}
        style={styleVideo}
        autoPlay={autoPlay}
        muted={muted}
        loop={loop}
        preload='metadata'
        onLoadStart={event => this.handleEvent(event)}
        onLoadedData={event => this.handleEvent(event)}
        onPlay={event => this.handleEvent(event)}
        onPlaying={event => this.handleEvent(event)}
        playsInline
      >
        <source src={lazyplay?null:src} type="video/mp4" />
      </video>
    );
  }
}
Video.defaultProps = {
  handleEvent: () => {},
  styleVideo: {},
  poster: "",
  autoPlay: true
};

Video.propTypes = {
  url: PropTypes.string,
  handleEvent: PropTypes.func,
  poster: PropTypes.string,
  autoPlay: PropTypes.bool
};

export default Video;
