import React from 'react';
import { useDispatch } from 'react-redux';
import { loadTrackCookieData } from '../../actions';

const TrackingPixel = ({ url }) => {
  const dispatch = useDispatch();
  const loadTrackCookie = () => {
    dispatch(loadTrackCookieData());
  };

  return <iframe src={url} sandbox="allow-scripts" title="track" scrolling="no" frameBorder="0" height="1" width="1" style={{display: 'none'}} onLoad={loadTrackCookie}></iframe>;
};

export default TrackingPixel;
