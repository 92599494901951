const trackUrl = "https://3tt0xhv5u7.execute-api.us-east-1.amazonaws.com/prod/event";


const eventTemplate = {
  event: 'leadgen',
  app: 'landing-page',
  event_type: null,
  event_data: {},
  time: null,
  request: {
    session_id: '',
    ip: '##IP##',
    ua: '##UA##',
    referrer_url: '',
    current_url: ''
  },
  user: {
    'ga_user_id': null
  },
  tracking: {
    adv_id: null,
    campaign: null,
    gallery_id: null
  }
};




const sendChugEvent = async(tracking, eventType, data) => {
  let payload = eventTemplate;

  payload['event_type'] = eventType;
  payload['request']['session_id'] = tracking.session_id || null;
  payload['request']['current_url'] = window.location.href;
  payload['request']['referrer_url'] = document.referrer;
  payload['tracking']['adv_id'] = tracking.adv_id || null;
  payload['tracking']['campaign'] = tracking.campaign || null;
  payload['tracking']['gallery_id'] = tracking.gallery_id || null;
  payload['user']['ga_user_id'] = tracking.ga_user_id || null;
  payload['event_data'] = data;

  //console.log(payload);
  return window.fetch(trackUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'text/plain'
    },
    body: JSON.stringify(payload)
  }).then(function(response) {}).catch(function(ex) {});

};

export default sendChugEvent;
