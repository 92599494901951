import React, { useState } from 'react';
import { Waypoint } from 'react-waypoint';
import Video from './Video';

const VideoLazy = (props) => {
  let [shouldPlay, updatePlayState] = useState(false);

  const handleEnterViewport = ({
    waypointTop,
    currentPosition
  }) => {
    //console.log("Enter viewport", props.src, waypointTop, currentPosition)
    if (waypointTop === 0) return;
    updatePlayState(true);
  };

  const handlePositionChange = (props) => {
    //console.log(props)
    //updatePlayState(true);
  };

  return (
    <Waypoint 
      onEnter={handleEnterViewport}
      onPositionChange={handlePositionChange}
      bottomOffset='-200px'
      >
      <Video {...props} autoPlay lazyplay={!shouldPlay}/> 
    </Waypoint>
  );
};

export default VideoLazy;
