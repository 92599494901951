import qs from 'query-string';
import phpUnserialize from 'phpunserialize';

/**
 * Performs a deep merge of `source` into `target`.
 * Mutates `target` only but not its objects and arrays.
 *
 * @author inspired by [jhildenbiddle](https://stackoverflow.com/a/48218209).
 */
export function mergeDeep(target, source) {
  const isObject = (obj) => obj && typeof obj === 'object';

  if (!isObject(target) || !isObject(source)) {
    return source;
  }

  Object.keys(source).forEach(key => {
    const targetValue = target[key];
    const sourceValue = source[key];

    if (Array.isArray(targetValue) && Array.isArray(sourceValue)) {
      target[key] = targetValue.concat(sourceValue);
    }
    else if (isObject(targetValue) && isObject(sourceValue)) {
      target[key] = mergeDeep(Object.assign({}, targetValue), sourceValue);
    }
    else {
      target[key] = sourceValue;
    }
  });

  return target;
}

export const getRootDomain = () => {
  let domainTesting;
  let parts = window.location.hostname.split('.');
  let partsLength = parts.length - 2;
  for (let i = 0; i < partsLength; i++) {
    parts.shift(); //remove sub-domains one by one
  }
  let rootDomain = parts.join('.');

  if (rootDomain === 'amazonaws.com') {
    domainTesting = getParameter('domain');
    if (domainTesting) return domainTesting;
  }
  return rootDomain;
};

export const getParameter = (name) => {
  let args = qs.parse(window.location.search);
  return (args || {})[name] || null;
};

export const replaceSubdomain = (url, subdomain) => {
  //TODO: make sure there's a subdomain, if there isn't, add instead of replace
  return url.replace(url.split(/[/.]+/)[1], subdomain);
};

export const removeURLParameter = (url, parameter) => {
  //prefer to use l.search if you have a location/link object
  var urlparts = url.split('?');
  if (urlparts.length >= 2) {

    var prefix = encodeURIComponent(parameter) + '=';
    var pars = urlparts[1].split(/[&;]/g);

    //reverse iteration as may be destructive
    for (var i = pars.length; i-- > 0;) {
      //idiom for string.startsWith
      if (pars[i].lastIndexOf(prefix, 0) !== -1) {
        pars.splice(i, 1);
      }
    }

    return urlparts[0] + (pars.length > 0 ? '?' + pars.join('&') : '');
  }
  return url;
};

export const getLargestPicture = (pictures) => {
  if (!pictures) return null;
  let keys = Object.keys(pictures);
  let largest_size = 0;
  let largest_key = "";
  keys.forEach(function(key) {
    let res = key.split('x');
    if (parseInt(res[0], 10) > largest_size) {
      largest_size = parseInt(res[0], 10);
      largest_key = key;
    }
  });
  return pictures[largest_key];
};

export const canUseWebP = ()=>{
 
  if ("object" !== typeof document) {
      return false;
  }

  const canvas = document.createElement("canvas");
  if (!canvas.getContext || !canvas.getContext("2d")) {
      return false;
  }
  return canvas.toDataURL("image/webp").indexOf("data:image/webp") === 0
}

export const getOptimizedImageUrl = (src, imageQuality = null, maxWidth = null) => {
  let vWidth = maxWidth || window.innerWidth || document.documentElement.clientWidth;
  if (window.devicePixelRatio > 1) vWidth = vWidth * 2;
  if (vWidth > 1920) vWidth = 1920;
  maxWidth = Math.ceil(vWidth / 40) * 40;
  if (!src) return null;
  if (src.indexOf('?') !== -1) return src;

  //If Contentful, we modify to accomodate width
  if (src.indexOf('images.ctfassets.') !== -1) {
    //We use GammaCDN to save costs
    src = src.replace("images.ctfassets.net", "ctf-images.gammacdn.com");

    src = `${src}?w=${maxWidth}`;
    if (imageQuality) src = `${src}&q=${imageQuality}`;
    if (canUseWebP()) src = `${src}&fm=webp`;
  }
  return src;
};

export const getHorizontalOptimizedImageUrl = (src, imageQuality = null, maxHeight = null) => {
  let vHeight = maxHeight || window.innerHeight || document.documentElement.clientHeight;
  if (vHeight > 1080) vHeight = 1080;
  maxHeight = Math.ceil(vHeight / 40) * 40;
  if (!src) return null;
  if (src.indexOf('?') !== -1) return src;

  //If Contentful, we modify to accomodate Height
  if (src.indexOf('images.ctfassets.') !== -1) {
    //We use GammaCDN to save costs
    src = src.replace("images.ctfassets.net", "ctf-images.gammacdn.com");
    src = `${src}?h=${maxHeight}`;
    if (imageQuality) src = `${src}&q=${imageQuality}`;
    if (canUseWebP()) src = `${src}&fm=webp`;
  }
  return src;
};

export const getOptimizedFormatImageUrl = (src) => {
  if (!src) return null;
  if (src.indexOf('?') !== -1) return src;

  //If Contentful, we modify to format
  if (src.indexOf('images.ctfassets.') !== -1) {
    //We use GammaCDN to save costs
    src = src.replace("images.ctfassets.net", "ctf-images.gammacdn.com");
    src = `${src}${canUseWebP()?'?fm=webp':''}`;
  }
  return src;
};

export const readCookie = (a) => {
  var b = document.cookie.match('(^|[^;]+)\\s*' + a + '\\s*=\\s*([^;]+)');
  return b ? b.pop() : '';
};

export function setCrossSubdomainCookie(name, value, days = null) {
  const assign = name + "=" + escape(value) + ";";
  let expires = "";
  if (days) {
    const d = new Date();
    d.setTime(d.getTime() + (days * 24 * 60 * 60 * 1000));
    expires = "expires=" + d.toUTCString() + ";";
  }
  const path = "path=/;";
  let domain = "";
  if (document.location.hostname !== "localhost") {
    const hostPart = (new URL(window.location.href)).host.split('.');
    domain = hostPart.slice(((hostPart.length === 2 ? 2 : hostPart.length -1)*-1)).join('.');
  } else {
    domain = "localhost";
  }
  domain = "domain=" + domain + ";";
  document.cookie = assign + expires + path + domain;
}

export const getTrackingObject = () => {
  let tracking = {};
  let cookie = decodeURIComponent(decodeURIComponent(readCookie('ssale') || readCookie('psale')));
  if (cookie === "" || !cookie) {
    return {};
  }
  try {
    tracking = phpUnserialize(cookie);
  }
  catch (e) {}
  return tracking;
};
export function loadPromoMessages(sitetag) {
  let scriptElement = document.createElement('script');
  scriptElement.type = 'text/props';
  scriptElement.appendChild(document.createTextNode(`{ "sitetag": "${sitetag}"}`));
  document.head.appendChild(scriptElement);
  scriptElement = document.createElement('script');
  scriptElement.type = 'text/javascript';
  scriptElement.src = "https://d3a3ewgd1iewwz.cloudfront.net/promomessages/dist.js";
  document.head.appendChild(scriptElement);
}

export const fontLoader = (family) => {
  var headID = document.getElementsByTagName('head')[0];
  var link = document.createElement('link');
  link.type = 'text/css';
  link.rel = 'stylesheet';
  headID.appendChild(link);
  link.href = 'https://fonts.googleapis.com/css?family=' + family + '&display=swap';
};


export const getShortlanguage = () => {
  let lang = window.navigator.languages ? window.navigator.languages[0] : null;
  lang = lang || window.navigator.language || window.navigator.browserLanguage || window.navigator.userLanguage;

  let shortLang = lang;
  if (shortLang.indexOf('-') !== -1)
    shortLang = shortLang.split('-')[0];

  if (shortLang.indexOf('_') !== -1)
    shortLang = shortLang.split('_')[0];
  return shortLang;
};

export const capitalize = (s) => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};


export const dedupeArray = (ary, keyField, removeNullField = null) => {
  if (!ary) return ary;
  var obj = {};
  for (var i = 0, len = ary.length; i < len; i++)
    obj[ary[i][keyField]] = ary[i];
  ary = [];
  for (var key in obj) {
    if (!removeNullField || (removeNullField && obj[key][removeNullField] !== null)) {
      ary.push(obj[key]);
    }
  }
  return ary;
};
const lib={
  mergeDeep,
  getRootDomain,
  getParameter,
  getLargestPicture,
  getOptimizedImageUrl,
  getHorizontalOptimizedImageUrl,
  canUseWebP,
  getOptimizedFormatImageUrl,
  setCrossSubdomainCookie,
  loadPromoMessages,
  fontLoader,
  readCookie,
  getShortlanguage,
  replaceSubdomain,
  removeURLParameter,
  capitalize,
  getTrackingObject,
  dedupeArray
};
export default lib;
