import React, { useState } from 'react';
import { Waypoint } from 'react-waypoint';
import Image from './Image';

const ImageLazy = (props) => {
  let [shouldDisplay, updateDisplayState] = useState(false);

  let handleEnterViewport = function() {
    updateDisplayState(true);
  };

  return (
    <Waypoint 
      onEnter={handleEnterViewport}
      bottomOffset='-200px'
    >
    {shouldDisplay?<Image {...props}/>:<div ref={props.innerRef}/>}
    </Waypoint>
  );
};

export default ImageLazy;
//export default Image;
