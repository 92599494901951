import React from 'react';
import styled from 'styled-components';
import useTracking from '../Tracking/useTracking';

const HeaderContainer = styled.div`
    display: block;
    margin-top: 68px;
`;

const Header = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    height: 68px;
    z-index: 10;
    background: #000;
`;

const HeaderWrapper = styled.div`
    display: flex;
    flex-wrap: no-wrap;
    justify-content: space-between;
    padding: 0 10px 0 44px;
    margin: auto;
    height: 100%;

    @media (max-width: 600px){
        padding-left: 10px;
    }
`;

const HeaderLeft = styled.div`
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-content: stretch;
    align-items: center;
    flex: 0 0 auto;
    height: 100%;
`;

const LogoLink = styled.a`
    height: ${p => p.logoHeight};
    margin-right: 10px;
    @media (max-width: 600px){
        height: unset;
    }     
`;

const Logo = styled.img`
    display: block;
    height: 100%;
    @media (max-width: 600px){
        width: 140px;
        height: auto;
    }    
`;

const LogoSlogan = styled.span`
    font-size: 12px;
    font-weight: normal;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: #fff;

    @media (max-width: 800px){
        display: none;
    }
`;

const HeaderRight = styled.div`
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: no-wrap;
    align-content: stretch;
    flex: 0 0 auto;
    height: 100%;
`;

const BrandTypeImage = styled.img`
    display: block;
    margin-right: 10px;

    @media (max-width: 800px){
        display: none;
    }
`;

const StudioImage = styled(BrandTypeImage)
    `
    width: 160px;
`;

const SeriesImage = styled(BrandTypeImage)
    `
    width: 200px;
`;

const HeaderLink = styled.a`
    height: 36px;
    vertical-align: middle;
    padding: 0 10px;
    border: 1px solid transparent;
    font-size: 16px;
    font-weight: bold;
    line-height: 36px;
    text-transform: uppercase;
    letter-spacing: 1px;
    text-decoration: none;

    @media (max-width: 995px){
        font-size: 14px;
    }

    @media (max-width: 350px){
        font-size: 13px;
        padding: 0 5px;
    }
`

const JoinLink = styled(HeaderLink)
    `
    background: #01f4e0;
    background: linear-gradient(180deg,#01f4e0 1%,#09c6cd);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#01f4e0", endColorstr="#09c6cd", GradientType=0);
    color: #000;
    margin-right: 10px;

    @media (hover: hover) {
        &:hover {
            background: #01f4e0;
        }        
    }

    @media (max-width: 995px){
        margin-right: 5px;
    }

`

const LoginLink = styled(HeaderLink)
    `
    background: transparent;
    color: white;
    border-color: #01f4e0;
    margin-right: 0;

    @media (hover: hover) {
        &:hover {
            background: #01f4e0;
            color: #000;
        }        
    }
`
const ATSiteHeader = ({ headerProperties }) => {
    let {
        sitename,
        logo,
        slogan,
        brandType,
        loginLink,
        loginLinkTarget,
        sloganColor,
        logoHeight,
        loginText = 'Sign In',
        joinText = 'Join Now'
    } = headerProperties;

    const { trackAndRedirect, sendChugEvent } = useTracking();

    const handleJoin = (elem) => e => {
        e.stopPropagation();
        e.preventDefault();
        let payload = { component: 'Header', element: elem };
        trackAndRedirect(payload);
    };

    const handleLogin = (elem) => e => {
        e.stopPropagation();
        e.preventDefault();
        let payload = { component: 'Header', element: elem };
        sendChugEvent('click', payload);
        if (loginLinkTarget === '_blank') {
            window.open(loginLink, loginLinkTarget);
        }
        else {
            window.location.href = loginLink;
        }
    };

    return (
        <HeaderContainer>
            <Header>
                <HeaderWrapper>
                    <HeaderLeft>
                        <LogoLink logoHeight={logoHeight || '25px'} onClick={handleJoin('logo')} href='#' style={{ color: sloganColor || '#FFF' }}>
                            <Logo src={logo} alt={sitename} />
                        </LogoLink>
                        <LogoSlogan dangerouslySetInnerHTML={{ __html: slogan }} />
                    </HeaderLeft>
                    <HeaderRight>
                        {(() => {
                            switch (brandType) {
                                case 'studio':
                                    return <StudioImage src="https://ctf-images.gammacdn.com/cdyxtpbavd4i/4AiA8RUFvJO6xbamBIlszK/435a1d3e3d2f93ae693fb9da38d279f9/adultTimeStudio.svg" alt="An Adult Time Studio" />;
                                case 'series':
                                    return <SeriesImage src="https://ctf-images.gammacdn.com/cdyxtpbavd4i/7zW3TdwG4BhWz79fwdEgUP/be5ec6cf4186ee48f1ad65961187f5df/adultTimeOriginalSeries.svg" alt="An Adult Time Original Series" />;
                                default:
                                    return null;
                            }
                        })()}
                        <JoinLink className="lp-join-link" onClick={handleJoin('joinButton')} href='#'>{joinText}</JoinLink>
                        <LoginLink onClick={handleLogin('loginButton')} href={loginLink}>{loginText}</LoginLink>
                    </HeaderRight>
                </HeaderWrapper>
            </Header>
        </HeaderContainer>
    );
};

export default ATSiteHeader;
