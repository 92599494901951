import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { getRootDomain, capitalize } from "../../lib/utils.js";

const LegalFooterWrapper = styled.div `
    color: ${p => p.textColor};
    background: ${p => p.backgroundColor};
    text-align: center;
    padding: 0px;
    font-size: 12px;
    @media (max-width: 700px) {
        font-size: 10px;
    }    
`;

const LegalFooter = ({
  textColor = '#7b7b7b',
  backgroundColor = '#000',
  text = null,
  version = "epoch"
}) => {
  const [apiText, setApiText] = useState(text);
  const siteUrl = capitalize(getRootDomain());
  const apiUrl = `https://www.gammaentertainment.com/legal/sitefooter?siteurl=${siteUrl}&version=${version}`;
  useEffect(() => {
    if (!apiText) window.fetch(apiUrl)
      .then(response => {
        if (!response.ok) return Promise.reject();
        return response.text();
      })
      .then(data => setApiText(data))
      .catch(err => err);
  }, [apiText, apiUrl]);
  return (
    <div style={{padding:20,backgroundColor:backgroundColor}}>
        <LegalFooterWrapper 
      dangerouslySetInnerHTML={{ __html: apiText }} 
      textColor={textColor} 
      style
      backgroundColor={backgroundColor}/>
    </div>
  );
};
export default LegalFooter;
